import React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

export default (props) => {
  return (
    <div className="w-full font-sans py-12 bg-dark-green">
      <div className="relative w-9/12 mx-auto max-w-screen-2xl">
        <div className="text-white">
          <h4 className="text-lg text-grey-c mb-3">Contact us for prompt, helpful advice:</h4>
          <h3 className="text-4xl mb-3"><a href="tel:+6448017481" title="+64-4 801 7481" rel="nofollow noopener" className="font-bold">+64-4 801 7481</a></h3>
          <p className="text-lg text-green"><a href="mailto:advice@mccabes.co.nz" title="advice@mccabes.co.nz" rel="nofollow noopener" className="border-b-2 border-transparent hover:border-green">advice@mccabes.co.nz</a></p>
        </div>
      </div>
    </div>
  )
}
