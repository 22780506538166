import React from "react"
import { Link, graphql } from "gatsby"

export default () => {
  return (
    <div className="w-full font-sans py-12">
      <div className="w-9/12 mx-auto max-w-screen-2xl">
        <div className="flex-none md:flex justify-between">
          <div className="w-full">
            <p className="text-grey-9">&copy;2022</p>
          </div>
        </div>
      </div>
    </div>
  )
}
