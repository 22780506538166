import React from "react"
import { Link } from 'gatsby'

export default () => (
  <div className="w-full text-base font-sans py-12">
    <div className="w-9/12 mx-auto max-w-screen-2xl">
      <div className="flex-none md:flex md:items-center md:justify-between">
        <div className="w-full md:w-6/12">
          <Link to="/"><img className="w-40 sm:w-60" src="/img/mc_cabe_logo.svg" alt="" /></Link>
        </div>
        <div className="hidden md:block md:w-6/12 md:text-right">
          <span className="text-4xl text-dark-green mb-2 block"><a href="tel:+6448017481" title="+64-4 801 7481" rel="nofollow noopener" className="font-bold">+64-4 801 7481</a></span>
          <p className="text-lg text-green"><a href="mailto:advice@mccabes.co.nz" title="advice@mccabes.co.nz" rel="nofollow noopener" className="border-b-2 border-transparent hover:border-green">advice@mccabes.co.nz</a></p>
        </div>  
      </div>
    </div>
  </div>
)