import React from "react"
import { Link } from 'gatsby'

export default () => (
  <div className="w-full text-base font-sans py-12 bg-green">
    <div className="w-9/12 mx-auto max-w-screen-2xl">
      <div className="flex-none md:flex justify-between">
        <div className="w-full md:w-6/12">
          <ul className="text-white space-y-2">
            <li><Link to="/" className="border-b-2 border-transparent hover:border-white">Home</Link></li>
            <li><Link to="/about/" className="border-b-2 border-transparent hover:border-white">About</Link></li>
            <li><Link to="/expertise/" className="border-b-2 border-transparent hover:border-white">Expertise</Link></li>
            <li><Link to="/people/" className="border-b-2 border-transparent hover:border-white">People</Link></li>
            <li><Link to="/contact/" className="border-b-2 border-transparent hover:border-white">Contact</Link></li>
          </ul>
        </div>
        <div className="w-full md:w-3/12 mt-12 md:mt-0">
          <h3 className="text-white font-bold text-2xl mb-3">Auckland</h3>
          <ul className="text-white">
            <li><a href="tel:+6493032050" title="+64-9 303 2050" className="border-b-2 border-transparent hover:border-white">+64-9 303 2050</a></li>
            <li><a href='mailto:advice@mccabes.co.nz' title="advice@mccabes.co.nz" className="border-b-2 border-transparent hover:border-white">advice@mccabes.co.nz</a></li>
            <li className="mt-3 text-grey-c"><p>187 Hurstmere Road<br/>Takapuna<br />Auckland</p></li>
          </ul>
        </div>
        <div className="w-full md:w-3/12 mt-12 md:mt-0 md:text-right">
          <h3 className="text-white font-bold text-2xl mb-3">Wellington</h3>
          <ul className="text-white">
            <li><a href="tel:+6448017481" title="+64-4 801 7481" className="border-b-2 border-transparent hover:border-white">+64-4 801 7481</a></li>
            <li><a href='mailto:advice@mccabes.co.nz' title="advice@mccabes.co.nz" className="border-b-2 border-transparent hover:border-white">advice@mccabes.co.nz</a></li>
            <li className="mt-3 text-grey-c"><p>Level 5, 38&mdash;42<br/>Waring Taylor Street<br />Wellington</p></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)