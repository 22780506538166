exports.linkResolver = (doc) => {
  if (doc.type === 'people_page') {
    return `/people/${doc.uid}/`
  }
  if (doc.type === 'expertise_page') {
    return `/expertise/${doc.uid}/`
  }
  if (doc.type === 'experience_page') {
    return `/experience/${doc.uid}/`
  }
  if (doc.type === 'about') {
    return `/about/${doc.uid}/`
  }
  return '/'
}
