import React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

export default (props) => {
  return (
    <div className="w-full h-12 sm:h-48">
      <GatsbyImage style={{ width: "100%", height: "100%", display: "block", objectFit: 'cover' }} image={props.image.gatsbyImageData} alt={props.image.alt ? props.image.alt : ''} />
    </div>
  )
}
