import React from "react"
import { Link, graphql, StaticQuery } from 'gatsby'
import { linkResolver } from '../utils/link_resolver'
import { Disclosure } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Nav() {

  return (
    <StaticQuery
      query={graphql`
        query {
          prismicMainNavigation {
            data {
              expertise {
                heading
                link {
                  link_type
                  id
                  uid
                  type
                }
              }
              experience {
                heading
                link {
                  link_type
                  id
                  uid
                  type
                }
              }
              people {
                heading
                link {
                  link_type
                  id
                  uid
                  type
                }
              }
              about {
                heading
                link {
                  link_type
                  id
                  uid
                  type
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className="sticky top-0 z-50">
        <Disclosure as="div">
        {({ open }) => (
          <>
          <div className="w-full text-base font-sans py-6 bg-grey-e">
            <div className="w-9/12 mx-auto max-w-screen-2xl">
              <div className="block text-right lg:hidden">
                <Disclosure.Button>
                  <div className={open ? 'hidden' : 'inline'}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline focus:outline-none" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
                    </svg>
                  </div>
                  <div className={open ? 'inline' : 'hidden'}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline focus:outline-none" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>
                </Disclosure.Button>
              </div>
              <div className="hidden lg:flex lg:justify-end lg:gap-6 lg:pl-6">
                <Link to="/" activeClassName="text-dark-green border-b-2 border-dark-green group-hover:border-dark-green" className="text-dark-green border-transparent border-b-2 hover:border-dark-green">Home</Link> 
                <div className="group relative">
                  <Link to="/expertise/" partiallyActive={true} activeClassName="text-dark-green border-b-2 border-dark-green group-hover:border-dark-green" className="text-dark-green border-b-2 border-transparent">Expertise <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5 -rotate-0 group-hover:-rotate-180 transform" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
                  <div className="group-hover:block hidden absolute w-48 z-40 -left-5 pt-3">
                    <div className="overflow-hidden shadow-lg bg-white">
                      <div className="w-11/12 mx-auto max-w-screen-2xl px-3 py-6">
                        <div className="grid grid-cols-1 gap-3">
                          {data.prismicMainNavigation.data.expertise.map((entry) => (
                          <div key={entry.link.id}>
                            <Link to={linkResolver(entry.link)}>
                              <p className="leading-none inline text-base text-dark-green border-b-2 border-transparent hover:border-dark-green">{entry.heading}</p>
                            </Link>
                          </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group relative">
                  <Link to="/people/" partiallyActive={true} activeClassName="text-dark-green border-b-2 border-dark-green group-hover:border-dark-green" className="text-dark-green border-transparent border-b-2">People <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5 -rotate-0 group-hover:-rotate-180 transform" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
                  <div className="group-hover:block hidden absolute w-48 z-40 -left-5 pt-3">
                    <div className="overflow-hidden shadow-lg bg-white">
                      <div className="w-11/12 mx-auto max-w-screen-2xl py-6 px-3">
                        <div className="grid grid-cols-1 gap-3">
                          {data.prismicMainNavigation.data.people.map((entry) => (
                          <div key={entry.link.id}>
                            <Link to={linkResolver(entry.link)}>
                              <p className="leading-none inline text-base text-dark-green border-b-2 border-transparent hover:border-dark-green">{entry.heading}</p>
                            </Link>
                          </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group relative">
                  <Link to="/about/" partiallyActive={true} activeClassName="text-dark-green border-b-2 border-dark-green group-hover:border-dark-green" className="text-dark-green border-transparent border-b-2">About <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5 -rotate-0 group-hover:-rotate-180 transform" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
                  <div className="group-hover:block hidden absolute w-48 z-40 -left-5 pt-3">
                    <div className="overflow-hidden shadow-lg bg-white">
                      <div className="w-11/12 mx-auto max-w-screen-2xl py-6 px-3">
                        <div className="grid grid-cols-1 gap-3">
                          {data.prismicMainNavigation.data.about.map((entry) => (
                          <div key={entry.link.id}>
                            <Link to={linkResolver(entry.link)}>
                              <p className="leading-none inline text-base text-dark-green border-b-2 border-transparent hover:border-dark-green">{entry.heading}</p>
                            </Link>
                          </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Link to="/contact/" activeClassName="text-dark-green border-b-2 border-dark-green group-hover:border-dark-green" className="text-dark-green border-transparent border-b-2 hover:border-dark-green">Contact</Link>
              </div>
            </div>
          </div>
          <Disclosure.Panel as="div" className="block lg:hidden">
            {({ close }) => (
              <div className="font-sans text-base pt-6 pb-24 border-b-2 border-grey-c bg-white">
                <div className="w-full mx-auto">
                  <div className="w-9/12 mx-auto space-y-3">
                    <div className="text-black">
                      <Link to="/">
                        Home
                      </Link>
                    </div>
                    <div>
                      <Disclosure as="div">
                        {({ open }) => (
                          <div>
                            <Disclosure.Button className="text-left w-full flex justify-between items-start">
                              <span className="text-dark-green">Expertise</span>
                              <span className="ml-6 h-7 flex items-center text-grey-9">
                                <svg xmlns="http://www.w3.org/2000/svg" className={classNames(open ? '-rotate-180 text-dark-green' : 'rotate-0', 'h-6 w-6 transform')} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                </svg>
                              </span>
                            </Disclosure.Button>
                            <Disclosure.Panel as="div" className="pl-6 pr-12">
                              <div className="pt-6 pb-3 space-y-3">
                                <Link to="/expertise/">
                                  <p className="font-bold leading-none inline text-base text-dark-green border-b-2 border-transparent hover:border-dark-green">Our expertise</p>
                                </Link>
                                {data.prismicMainNavigation.data.expertise.map((entry,i) => (
                                  <div key={`mobile_${i}`}>
                                    <Link to={linkResolver(entry.link)}>
                                      <span className="text-dark-green">
                                        {entry.heading}
                                      </span>
                                    </Link>
                                  </div>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </div>
                        )}
                      </Disclosure>
                    </div>
                    <div>
                      <Disclosure as="div">
                        {({ open }) => (
                          <div>
                            <Disclosure.Button className="text-left w-full flex justify-between items-start">
                              <span className="text-dark-green">People</span>
                              <span className="ml-6 h-7 flex items-center text-grey-9">
                                <svg xmlns="http://www.w3.org/2000/svg" className={classNames(open ? '-rotate-180 text-dark-green' : 'rotate-0', 'h-6 w-6 transform')} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                </svg>
                              </span>
                            </Disclosure.Button>
                            <Disclosure.Panel as="div" className="pl-6 pr-12">
                              <div className="pt-6 pb-3 space-y-3">
                                <Link to="/people/">
                                  <p className="font-bold leading-none inline text-base text-dark-green border-b-2 border-transparent hover:border-dark-green">Our people</p>
                                </Link>
                                {data.prismicMainNavigation.data.people.map((entry,i) => (
                                  <div key={`mobile_${i}`}>
                                    <Link to={linkResolver(entry.link)}>
                                      <span className="text-dark-green">
                                        {entry.heading}
                                      </span>
                                    </Link>
                                  </div>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </div>
                        )}
                      </Disclosure>
                    </div>
                    <div>
                      <Disclosure as="div">
                        {({ open }) => (
                          <div>
                            <Disclosure.Button className="text-left w-full flex justify-between items-start">
                              <span className="text-dark-green">About</span>
                              <span className="ml-6 h-7 flex items-center text-grey-9">
                                <svg xmlns="http://www.w3.org/2000/svg" className={classNames(open ? '-rotate-180 text-dark-green' : 'rotate-0', 'h-6 w-6 transform')} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                </svg>
                              </span>
                            </Disclosure.Button>
                            <Disclosure.Panel as="div" className="pl-6 pr-12">
                              <div className="pt-6 pb-3 space-y-3">
                                <Link to="/about/">
                                  <p className="font-bold leading-none inline text-base text-dark-green border-b-2 border-transparent hover:border-dark-green">About us</p>
                                </Link>
                                {data.prismicMainNavigation.data.about.map((entry,i) => (
                                  <div key={`mobile_${i}`}>
                                    <Link to={linkResolver(entry.link)}>
                                      <span className="text-dark-green">
                                        {entry.heading}
                                      </span>
                                    </Link>
                                  </div>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </div>
                        )}
                      </Disclosure>
                    </div>
                    <div className="text-dark-green">
                      <Link to="/contact/">
                        Contact
                      </Link>
                    </div>
                    <div className="text-dark-green">
                      <a href="tel:+6448017481" title="+6448017481" rel="nofollow noopener">
                        +64-4 801 7481
                      </a>
                    </div>
                    <div className="text-dark-green">
                      <a href="mailto:advice@mccabes.co.nz" title="advice@mccabes.co.nz" rel="nofollow noopener" className="border-b-2 border-transparent hover:border-green">
                        advice@mccabes.co.nz
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Disclosure.Panel>
          </>
          )}
          </Disclosure>
        </div>
      )}
    />
  )
}